/* html {
  scroll-behavior: smooth;
} */

/* CARDS lines effects */
.card {
  background: repeating-linear-gradient(
    45deg,
    #140f00,
    #241c00 10px,
    black 10px,
    rgb(26, 15, 0) 20px
  );
}
.canvas-wrapper,
.biography {
  margin-top: 0 !important;
}

/* 3D TEXT EFFECTS */
.page-not-found {
  --couleur: 0, 0, 0;
  display: flex;
  justify-content: center;
  font-family: "Exo";
  color: white;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #acacac, 0 3px 0 #a0a0a0, 0 4px 0 #9b9a9a,
    0 5px 0 #888787, 0 6px 1px rgba(var(--couleur), 0.1),
    0 0 5px rgba(var(--couleur), 0.1), 0 1px 3px rgba(var(--couleur), 0.3),
    0 3px 5px rgba(var(--couleur), 0.3), 0 5px 10px rgba(var(--couleur), 0.25),
    0 10px 10px rgba(var(--couleur), 0.2),
    0 20px 20px rgba(var(--couleur), 0.15);
  font-size: calc(100vw / 10);
}

.portraits-card {
  width: 100%;
}
